import "./MenuButton.css"

interface MenuButtonProps{
  value : string;
  onSubmit : () => void;
  isSmallFont? : boolean;
  show?: boolean;
}

export default function MenuButton({ value, onSubmit, isSmallFont=false, show=true } : MenuButtonProps) {
    if(!show){
      return <></>
    }

    const classes = "menuButton btn btn-primary " + (isSmallFont ? 'smallFont' : 'normalFont')

    return (
        <div className="mt-4 d-flex justify-content-center">
          <button className={classes} onClick={e => {
            e.preventDefault();
            onSubmit();
          }}>{value}</button>
        </div>
    );
  }