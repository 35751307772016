import Points from "../Points/Points";
import Timer from "../Timer/Timer";
import GameMode from "../../shared/gamemodes";

interface ProgressFacadeProps{
    points: number;
    timer: number;
    gameMode: number;
}

export default function ProgressFacade({ points, timer, gameMode } : ProgressFacadeProps){
    if(gameMode === GameMode.SINGLEPLAYER){
        return <Timer seconds={timer}/>;
    }else{
        return <Points numberOfPoints={points}/>;
    }
}