import Controls from "../Controls/Controls";
import "./PlayerInfo.css";
import ProgressFacade from "../ProgressFacade/ProgressFacade";
import GameMode from "../../shared/gamemodes";

interface PlayerInfoProps{
    points: number;
    timer?: number;
    name: string;
    isPlayerOne: boolean;
    gameMode: number;
    isOnlinePlayerTwo : boolean;
}

export default function PlayerInfo({ points, timer, name, isPlayerOne, gameMode, isOnlinePlayerTwo } : PlayerInfoProps){ 
    let id;
    if(isPlayerOne)
        id = 'playerOneInfo'
    else
        id = gameMode === GameMode.SINGLEPLAYER ? 'score' : 'playerTwoInfo';

    const classes = "playerInfo position-absolute top-0 " + ((isPlayerOne) ? "start-0" : "end-0");

    if(gameMode === GameMode.SINGLEPLAYER && !isPlayerOne)
        //TODO: Show total score on singleplayer
        return <div id={id} className={classes}>SCORE</div>

    return <div id={id} className={classes}>
        <div className="d-flex justify-content-between">
            <div>{name}</div>
            <div><ProgressFacade points={points} timer={timer ?? 0} gameMode={gameMode}/></div>
        </div>
        <div><Controls gameMode={gameMode} isPlayerOne={isPlayerOne} isOnlinePlayerTwo={isOnlinePlayerTwo}/></div>
        </div>
}