import buttonMatrix from './buttons.json' 

// Operator enum for what operator is selected by player
enum Operator{
    NoOperator,
    Addition,
    Subtraction,
    Division,
    Multiplication
}

// Returnable flag whenever a player moves to give ephemeral state info
export enum Flag{
    NO_FLAG,
    DAMAGED_HIGH_HEALTH,
    DAMAGED_LOW_HEALTH,
    PLAYER_ONE_DISPLAY_INVALID,
    PLAYER_ONE_MOVE_INVALID,
    PLAYER_TWO_DISPLAY_INVALID,
    PLAYER_TWO_MOVE_INVALID,
    SWITCHED_PLAYER_POSITIONS,
    FINISHED_GAME,
    STARTED_MATCH,
    FINISHED_MATCH,
    BOARD_LOCKED,
    TIMEOUT
}

// Constants providing non configurable bounds of the game
const MAX_HEALTH = 8;
const MAX_POINTS = 3;
const INITIAL_TIME = 180;
const NUMBER_OF_BUTTON_ROWS = 4;
const NUMBER_OF_BUTTON_COLUMNS = 9;
const ABSOLUTE_MAXIMUM_DISPLAY_NUMBERS = 10;

const operatorStrings : {[key : string] : Operator} = {
    '': Operator.NoOperator,
    '+': Operator.Addition,
    '-': Operator.Subtraction,
    '/': Operator.Division,
    '*': Operator.Multiplication
}

//Player class which contains state of a player
class Player {
    position : number;  
    health : number;  
    display : string;
    operator : Operator;
    userMemory : number;
    displayMemory : number;
    isClear : boolean;
    points : number;

    constructor(position : number, health : number, display : string, operator : Operator, userMemory : number, displayMemory : number, isClear : boolean, points : number){
        this.position = position;
        this.health = health;
        this.display = display;
        this.operator = operator;
        this.userMemory = userMemory;
        this.displayMemory = displayMemory;
        this.isClear = isClear;
        this.points = points;
    }
}

// Calculator board class which contains states for the whole game
export class Board {
    goal : number;
    maxGoalDigits : number;
    maxDisplayDigits : number;
    playerOne : Player;
    playerTwo : Player; 
    locked : boolean;   

    constructor(maxGoalDigits : number, maxDisplayDigits : number){
        this.goal = 0;
        this.maxGoalDigits = maxGoalDigits;
        this.maxDisplayDigits = maxDisplayDigits;
        this.playerOne = new Player(0, MAX_HEALTH, '0', Operator.NoOperator, 0, 0, false, 0);
        this.playerTwo = new Player(1, MAX_HEALTH, '0', Operator.NoOperator, 0, 0, false, 0);
        this.locked = false;
    }

    // This method goes through the logic of moving a player a certain direction, as well as handling player conflicts
    private setNewRelativePlayerPosition(isPlayerOne : boolean, positionChange : number){
        if(this.locked)
            return Flag.BOARD_LOCKED;
        
        const movingPlayer = isPlayerOne ? this.playerOne : this.playerTwo;
        const otherPlayer = isPlayerOne ? this.playerTwo : this.playerOne;

        const playerCartesianOldPosition = this.getPlayerPositionCartesian(movingPlayer.position);

        const isPlayerTryingToCrossBound = (playerCartesianOldPosition.x === 0 && positionChange === -1)
                                        || (playerCartesianOldPosition.x === NUMBER_OF_BUTTON_COLUMNS - 1 && positionChange === 1)
                                        || (playerCartesianOldPosition.y === 0 && positionChange === -NUMBER_OF_BUTTON_COLUMNS)
                                        || (playerCartesianOldPosition.y === NUMBER_OF_BUTTON_ROWS - 1 && positionChange === NUMBER_OF_BUTTON_COLUMNS)

        if(isPlayerTryingToCrossBound)
            return isPlayerOne ? Flag.PLAYER_ONE_MOVE_INVALID : Flag.PLAYER_TWO_MOVE_INVALID;        

        const isPlayerTryingToCrossOtherPlayer = movingPlayer.position + positionChange === otherPlayer.position;
        if(isPlayerTryingToCrossOtherPlayer){

            //Switch player places if other player is 0 health now
            if(--otherPlayer.health === 0){
                otherPlayer.health = MAX_HEALTH;
                const oldMovingPlayerPosition = movingPlayer.position;
                movingPlayer.position = otherPlayer.position;
                otherPlayer.position = oldMovingPlayerPosition;
                return Flag.SWITCHED_PLAYER_POSITIONS;
            }else{
                return otherPlayer.health < MAX_HEALTH / 2 ? Flag.DAMAGED_LOW_HEALTH : Flag.DAMAGED_HIGH_HEALTH;
            }
        }   

        movingPlayer.position += positionChange;

        return Flag.NO_FLAG;
    }    

    private getPlayerPositionCartesian(position : number){     
        const x = position % NUMBER_OF_BUTTON_COLUMNS;
        const y = Math.floor(position / NUMBER_OF_BUTTON_COLUMNS);
        
        return {x, y};
    }

    private truncateDisplayNumber(displayNumber : number){
        const displayParts = displayNumber.toString().split('.')
        return displayParts[0] + (displayParts.length === 2 ? '.' + displayParts[1].substring(0, + this.maxDisplayDigits + 1) : '')
    }

    startNextMatch(){
        this.playerOne = new Player(0, MAX_HEALTH, '0', Operator.NoOperator, 0, 0, false, this.playerOne.points);
        this.playerTwo = new Player(1, MAX_HEALTH, '0', Operator.NoOperator, 0, 0, false, this.playerTwo.points);
        this.generateAndSetGoalNumber();
        this.locked = false;

        return Flag.STARTED_MATCH;
    }

    // Set the goal to a newly generated number
    generateAndSetGoalNumber(){
        this.goal = Math.round(Math.random() * Math.pow(10, this.maxGoalDigits));
    }

    // Moves a player left
    moveLeft(isPlayerOne : boolean){      
        return this.setNewRelativePlayerPosition(isPlayerOne, -1);
    };

    // Moves a player right
    moveRight(isPlayerOne : boolean){
        return this.setNewRelativePlayerPosition(isPlayerOne, 1);        
    };

    // Moves a player down
    moveDown(isPlayerOne : boolean){        
        return this.setNewRelativePlayerPosition(isPlayerOne, NUMBER_OF_BUTTON_COLUMNS);
    };

    // Moves a player up
    moveUp(isPlayerOne : boolean){
        return this.setNewRelativePlayerPosition(isPlayerOne, -NUMBER_OF_BUTTON_COLUMNS);
    };

    confirmSelection(isPlayerOne : boolean) { 
        if(this.locked)
            return Flag.BOARD_LOCKED;

        const player = isPlayerOne ? this.playerOne : this.playerTwo;
        const oldPlayerDisplay = player.display;

        const playerCartesianPosition = this.getPlayerPositionCartesian(player.position);
        const currentPlayerSelection = buttonMatrix[playerCartesianPosition.y][playerCartesianPosition.x];
        const getEffectiveLength = (playerDisplay : string) => playerDisplay.toString().replace('.', '').length

        const applyMathFunctionToPlayerDisplay = (foo : (playerDisplayNumber : number) => number) => convertConstantToPlayerDisplay(foo(parseFloat(player.display)));        

        const convertConstantToPlayerDisplay = (constant : number) => {
            player.isClear = true;

            return this.truncateDisplayNumber(constant);
        }

        switch(currentPlayerSelection.value + (currentPlayerSelection.power === 0 ? '' : currentPlayerSelection.power.toString())){
            case '0': case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9':
                if(player.display === '0' || player.isClear){
                    player.display = '';  
                    player.isClear = false; 
                }
                if(getEffectiveLength(player.display) < this.maxDisplayDigits)
                    player.display += currentPlayerSelection.value;   
            break;
            case '.':
                if(player.isClear){
                    player.display = '';  
                    player.isClear = false; 
                }
                
                if(!player.display.includes('.'))
                    player.display += '.';
            break;
            case 'DEL':
                if(!player.isClear){
                    player.display = player.display.length === 1 ? '0' : player.display.substring(0, player.display.length - 1)
                }
            break;
            case 'e':
                player.display = convertConstantToPlayerDisplay(Math.E);
            break;
            case 'pi':
                player.display =convertConstantToPlayerDisplay(Math.PI);
            break;
            case '+': case '-': case '*': case '/':
                player.operator = operatorStrings[currentPlayerSelection.value];
                player.displayMemory = parseFloat(player.display);
                player.isClear = true;
            break;
            case '=':
                const currentPlayerDisplay = parseFloat(player.display);

                if(player.displayMemory === 0){
                    break;
                }

                let newPlayerDisplay = 0;

                switch(player.operator){
                    case Operator.Addition:
                        newPlayerDisplay = player.displayMemory + currentPlayerDisplay;
                    break;
                    case Operator.Subtraction:
                        newPlayerDisplay = player.displayMemory - currentPlayerDisplay;
                    break;
                    case Operator.Multiplication:
                        newPlayerDisplay = player.displayMemory * currentPlayerDisplay;
                    break;
                    case Operator.Division:
                        newPlayerDisplay = player.displayMemory / currentPlayerDisplay;
                    break;
                    case Operator.NoOperator:
                    default:
                    break;
                }

                player.display = this.truncateDisplayNumber(newPlayerDisplay);
                player.displayMemory = 0;
                player.operator = Operator.NoOperator;
                player.isClear = true;
            break;

            case 'round':
                player.display = applyMathFunctionToPlayerDisplay(Math.round)
            break;
            case 'sin':
                player.display = applyMathFunctionToPlayerDisplay(Math.sin);
            break;
            case 'cos':
                player.display = applyMathFunctionToPlayerDisplay(Math.cos);
            break;
            case 'tan':
                player.display = applyMathFunctionToPlayerDisplay(Math.tan);
            break;
            case 'sin-1':
                player.display = applyMathFunctionToPlayerDisplay(Math.asin);
            break;
            case 'cos-1':
                player.display = applyMathFunctionToPlayerDisplay(Math.acos);
            break;
            case 'tan-1':
                player.display = applyMathFunctionToPlayerDisplay(Math.atan);
            break;
            case 'x2':
                player.display = applyMathFunctionToPlayerDisplay((playerDisplay) => Math.pow(playerDisplay, 2))
            break;
            case '√x':
                player.display = applyMathFunctionToPlayerDisplay(Math.sqrt)
            break;
            case 'ln':
                player.display = applyMathFunctionToPlayerDisplay(Math.log)
            break;
            case 'log':
                player.display = applyMathFunctionToPlayerDisplay(Math.log10)
            break;
            case 'M+':
                player.userMemory += parseFloat(player.display);
            break;
            case 'M-':
                player.userMemory -= parseFloat(player.display);
            break;
            case 'MR':
                convertConstantToPlayerDisplay(player.userMemory)
            break;
            case 'MC':
                player.userMemory = 0;
            break;
            case 'AC':
                player.display = '0'
                player.userMemory = 0;
                player.displayMemory = 0;
                player.operator = Operator.NoOperator
                player.isClear = false;
            break;
            case 'CE':
                player.display = '0'
            break;
        }

        //Handle win
        if(this.goal === parseFloat(player.display)){
            this.locked = true;

            return (++player.points < MAX_POINTS) ? Flag.FINISHED_MATCH : Flag.FINISHED_GAME          
        }

        //Ensure that new player display do not exceed crazy number limit
        if(getEffectiveLength(player.display) > ABSOLUTE_MAXIMUM_DISPLAY_NUMBERS){
            player.display = oldPlayerDisplay;
            return isPlayerOne ? Flag.PLAYER_ONE_DISPLAY_INVALID : Flag.PLAYER_TWO_DISPLAY_INVALID
        }

        return Flag.NO_FLAG;
    }
}