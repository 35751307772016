import MenuButton from "../MenuButton/MenuButton";
import GameMode from "../../shared/gamemodes";

interface HelpProps {
  setMenu : (menu : number) => void;
}
export default function Help({ setMenu } : HelpProps) {
    return (
      <div>
        <h1 className="title mt-5 centered">Help</h1>
        <h4 className="mx-2 text-justify">This is a game which tests your quick maths against players with a twist. To win, you must get a specified number on your calculator display. You do this by using the arrow keys to move around the cursor across the calculator, and pressing spacebar or enter to press the currently selected number. Careful though, You can only type in one number at a time! To get to the specified number, you must use arithmetic and functions to get what you want.</h4>
        <h3 className="mt-3 mx-2"><b>Singleplayer</b></h3>
        <h4 className="mx-2">You are given three minutes to score as many numbers as possible. Try your best to get your name on the top 5 leaderboard!</h4>
        <h3 className="mt-3 mx-2"><b>Local Multiplayer</b></h3>
        <h4 className="mx-2">You have the freedom to choose how many numbers you can enter at once, as well as the range of the goal's number. You get to play with a friend locally on the same keyboard where player one uses WASD keys and space, and player two uses arrow keys and enter. The controls are a bit cramped but the competition will be personal. You have to score three numbers before your opponent does.</h4>
        <h3 className="mt-3 mx-2"><b>Online Multiplayer</b></h3>
        <h4 className="mx-2">You have to battle an online player to see who can score three numbers the fastest. Unlike local multiplayer, you can only play online with a limit of one number to get to the goal.</h4>
        <MenuButton value="Back" onSubmit={() => setMenu(GameMode.MAIN)}/>
      </div>
    );
  }