import MenuButton from "../MenuButton/MenuButton";
import MenuProps from "../../shared/MenuProps";
import GameMode from "../../shared/gamemodes";

export default function MainMenu({ setMenu, setIsGame, connected } : MenuProps){
    if(connected)
      return (
        <div>
          <div>
            <h1 className="title mt-5 d-flex justify-content-center">🖩 Calc Wars (BETA) 🖩</h1>      
            <br></br>
            <MenuButton value="Singleplayer Leaderboard" onSubmit={() => setMenu(GameMode.SINGLEPLAYER)}/>            
            <MenuButton value="Local Multiplayer" onSubmit={() => setMenu(GameMode.LOCAL_MULTIPLAYER)}/>
            <MenuButton value="Online Multiplayer" onSubmit={() => setMenu(GameMode.ONLINE_MULTIPLAYER)}/>
            <MenuButton value="Help" onSubmit={() => setMenu(GameMode.HELP)}/>
          </div>
    
          <div className="position-absolute bottom-0 end-0 mx-2 mb-2">v0.4 by Andrew Buhagiar</div>
        </div>
      );
    else
        return <h1>Connecting...</h1>
  }