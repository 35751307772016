import './Error.css'

interface ErrorProps{
    message : string;
}

export default function Error({ message } : ErrorProps){
    if(message)
        return <div className="errorMessage position-absolute bottom-0 start-0">ERROR: {message.toUpperCase()}</div>;
    return <></>;
}