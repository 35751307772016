import MenuButton from "../MenuButton/MenuButton";
import MenuProps from "../../shared/MenuProps";
import GameMode from "../../shared/gamemodes";
import { useRef, useState } from "react";

export default function OnlineMultiplayerMenu({ setMenu, setIsGame, playerOneName, playerTwoName, maxDisplayDigits, maxGoalDigits, setPlayerOneName, setPlayerTwoName, setMaxDisplayDigits, setMaxGoalDigits, online } : MenuProps) {
    const [status, setStatus] = useState('');
    const connectingToPlayer = useRef(false);

    return (
      <div className="centered">
        <h1 className="title mt-6">Online Multiplayer</h1>
        <h2 className="mt-5">Test your calculator mettle against people across the globe</h2>
        <form className="mt-4">
          <div>
            <h3>Nickname</h3>
          </div>
          <form onChange={(event : any) => setPlayerOneName!(event.target.value)}>
            <input id="playerName" className="inputBox" type="text" name="playerName" value={playerOneName}/>
          </form>
          <MenuButton value="Play" onSubmit={() => {
            const searchForNewGame = () => {
              //TODO: Disable button when started search
              online!.send(JSON.stringify({header: "REQUEST", name: playerOneName, gamemode: "MULTIPLAYER"}));
              setStatus("Searching for game...");
            }

            searchForNewGame();

            online!.addMessageHandler((data : any) => {
              const response = JSON.parse(data.data);
              switch(response.header){
                case 'MATCH':
                  setStatus("Found player...")
                  connectingToPlayer.current = true;
                  setTimeout(() => {
                    if(connectingToPlayer.current){
                      searchForNewGame();
                    }
                  }, 5000);
                break;
                case 'BOARD':
                  if(connectingToPlayer){
                    setIsGame(true);
                    connectingToPlayer.current = false;
                  }
                break;
                default:
                break;
              }
            })
          }}/>
          <MenuButton value="Back" onSubmit={() => setMenu(GameMode.MAIN)}/>
          <h3>{status}</h3>
        </form>
      </div>
    );
  }