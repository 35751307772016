import { useRef, useState } from 'react';
import SinglePlayerMenu from './components/SinglePlayerMenu/SinglePlayerMenu'
import LocalMultiplayerMenu from './components/LocalMultiplayerMenu/LocalMultiplayerMenu'
import OnlineMultiplayerMenu from './components/OnlineMultiplayerMenu/OnlineMultiplayerMenu'
import Help from './components/Help/Help'
import MainMenu from './components/MainMenu/MainMenu';
import "./App.css";
import Game from './components/Game/Game';
import GameMode from './shared/gamemodes';
import { Online } from './shared/MenuProps';

export default function App() {
  const DOMAIN_NAME = "andrewbuhagiar.com"
  
  const [menu, setMenu] = useState(GameMode.MAIN);
  const [maxDisplayDigits, setMaxDisplayDigits] = useState(1);
  const [maxGoalDigits, setMaxGoalDigits] = useState(2);
  const [playerOneName, setPlayerOneName] = useState(getRandomPlayerName());
  const [playerTwoName, setPlayerTwoName] = useState(getRandomPlayerName());
  const [isGame, setIsGame] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [connected, setConnected] = useState(false);
  let ws = useRef(new WebSocket(`wss://${DOMAIN_NAME}`));

  const send = (message : string) => {
    ws.current.send(message);
  }

  const addMessageHandler = (handler : EventListenerOrEventListenerObject) => {
    ws.current.addEventListener("message", handler);
  }

  if(!connected){
    ws.current = new WebSocket(`wss://${DOMAIN_NAME}:2096`);
    setConnected(true);
            
    addMessageHandler((data : any) => {
      //Handle match
      const response = JSON.parse(data.data);
      switch(response.header){
        case 'MATCH':
          online!.matchId.current = response.matchId;
          online!.playerId.current = response.playerId;
          online!.isPlayerOne.current = response.isPlayerOne;
          online!.opponentName.current = response.opponentName;
          //TODO: Only accept if actually looking for match
          ws.current.send(JSON.stringify({header: "ACCEPT", matchId: response.matchId, playerId: response.playerId, name: playerOneName}));
        break;
        case 'BOARD':
          online!.goal.current = response.board.goal
        break;
        case 'ALIVE':
          ws.current.send(JSON.stringify({header: "ALIVE"}));
        break;
        case 'LEADERBOARD':
          console.log(response)
          setLeaderboard(response.data);
        break;
        default:
        break;
      }
    });
  }

  let online : Online = {
    send,
    addMessageHandler,
    matchId : useRef(''),
    playerId : useRef(''),
    goal: useRef(0),
    isPlayerOne: useRef(false),
    opponentName: useRef('')
  } 

  if(isGame){
    return <Game gameMode={menu} setMenu={setMenu} setIsGame={setIsGame} playerOneName={playerOneName} playerTwoName={playerTwoName} maxDisplayDigits={maxDisplayDigits} maxGoalDigits={maxGoalDigits} online={online}/>
  }
  switch(menu){
    case GameMode.SINGLEPLAYER: return <SinglePlayerMenu setMenu={setMenu} setIsGame={setIsGame} playerOneName={playerOneName} setPlayerOneName={setPlayerOneName} online={online} leaderboard={leaderboard}/>
    case GameMode.LOCAL_MULTIPLAYER: return <LocalMultiplayerMenu setMenu={setMenu} setIsGame={setIsGame} playerOneName={playerOneName} playerTwoName={playerTwoName}  maxDisplayDigits={maxDisplayDigits} maxGoalDigits={maxGoalDigits} setPlayerOneName={setPlayerOneName} setPlayerTwoName={setPlayerTwoName} setMaxDisplayDigits={setMaxDisplayDigits} setMaxGoalDigits={setMaxGoalDigits}/>
    case GameMode.ONLINE_MULTIPLAYER: return <OnlineMultiplayerMenu setMenu={setMenu} setIsGame={setIsGame} playerOneName={playerOneName} playerTwoName={playerTwoName}  maxDisplayDigits={maxDisplayDigits} maxGoalDigits={maxGoalDigits} setPlayerOneName={setPlayerOneName} setPlayerTwoName={setPlayerTwoName} setMaxDisplayDigits={setMaxDisplayDigits} setMaxGoalDigits={setMaxGoalDigits} online={online}/>
    case GameMode.HELP: return <Help setMenu={setMenu}/>
    default: return <MainMenu setMenu={setMenu} setIsGame={setIsGame} connected={connected}/>
  }
}

const getRandomPlayerName = () => {
  const playerNames = ["Alpha", "Beta", "Gamma", "Delta", "Epsilon", "Zeta", "Eta", "Theta", "Iota", "Kappa", "Lambda", "Pi", "Rho", "Sigma", "Tau", "Psi", "Omega"]
  return playerNames[~~(Math.random()*playerNames.length-1)];
}
