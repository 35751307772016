import Point from "../Point/Point";

interface PointsProps{
    numberOfPoints : number;
}

export default function Points({ numberOfPoints } : PointsProps){
    return (
    <div>
        <Point isOn={numberOfPoints >= 1}/>
        <Point isOn={numberOfPoints >= 2}/>
        <Point isOn={numberOfPoints >= 3}/>
    </div>
    )
}