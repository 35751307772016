import GameMode from "../../shared/gamemodes";
import "./Display.css";

interface DisplayProps{
    gameMode : GameMode;
    isPlayerOne : boolean;
    isWin : boolean;
    value : string;
    isShake : boolean;
}

export default function Display({ gameMode, isPlayerOne, isWin, value, isShake } : DisplayProps){
    let id;
    if(isPlayerOne)
        id = 'playerOneNumber'
    else
        id = gameMode === GameMode.SINGLEPLAYER ? 'scoreNumber' : 'playerTwoNumber';

    const classes = (isWin ? 'win' : '') + " playerNumber" + (isShake ? ' shakelight' : '') + (isWin ? ' win' : '');

    return <div id={id} className={classes}>{value}</div>;
}