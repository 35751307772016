import MenuButton from "../MenuButton/MenuButton";
import MenuProps from "../../shared/MenuProps";
import GameMode from "../../shared/gamemodes";

export default function LocalMultiplayerMenu({ setMenu, setIsGame, playerOneName, playerTwoName, maxDisplayDigits, maxGoalDigits, setPlayerOneName, setPlayerTwoName, setMaxDisplayDigits, setMaxGoalDigits } : MenuProps) {
    const handleFormChange = (event : any) => {
      const value = event.target.value;
      switch(event.target.name){
        case 'maxDisplayDigits':
          setMaxDisplayDigits!(value); 
        break;
        case 'maxGoalDigits':
          setMaxGoalDigits!(value);
        break;
        case 'playerOneName':
          setPlayerOneName!(value);
        break;
        case 'playerTwoName':
          setPlayerTwoName!(value);
        break;

      }
    }

    return (
      <div className="centered">
        <h1 className="title mt-4">Local Multiplayer</h1>
        <h2 className="mt-4">Compete with your friend on the same keyboard to see who is the fastest</h2>
        <form className="mt-3" onChange={(event : any) => handleFormChange(event)}>
          <div>
            <h3>Maximum number of digits in goal number</h3>
          </div>
            <input className="inputBox" type="number" name="maxGoalDigits" value={maxGoalDigits}/>
          <br></br>
          <div className="mt-3">
            <h3>Maximum number of digits in player's display</h3>
            <input className="inputBox" type="number" name="maxDisplayDigits" value={maxDisplayDigits}/>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <table>
              <tr>
                <th className="px-2">
                  <h3>Player One Name</h3>
                  <input className="inputBox" type="text" name="playerOneName" value={playerOneName}/>
                </th>
                <th className="px-2">                
                  <h3>Player Two Name</h3>
                  <input className="inputBox" type="text" name="playerTwoName" value={playerTwoName}/>
                </th>
              </tr>
            </table>
          </div>
          
          <MenuButton value="Play" onSubmit={() => setIsGame(true)}/>
          <MenuButton value="Back" onSubmit={() => setMenu(GameMode.MAIN)}/>
        </form>
      </div>
    );
  }