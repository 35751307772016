import MenuButton from "../MenuButton/MenuButton"
import MenuProps from "../../shared/MenuProps"
import GameMode from "../../shared/gamemodes"

export default function SinglePlayerMenu({ setMenu, setIsGame, playerOneName, setPlayerOneName, online, leaderboard } : MenuProps) {
    const leaderboardList = [];

    leaderboardList.push(leaderboard?.map(leaderboardEntry => {
        return (
            <li key={leaderboardEntry.uuid}>
                <h4>{leaderboardEntry.name} - {leaderboardEntry.score}</h4>
            </li>
        )
    }))
    return (
        <div className="centered">
            <h1 className="title mt-4">Singleplayer</h1>
            <h2 className="mt-4">Try to calculate the most numbers in three minutes</h2>
            <h3 className="mt-4"><b>Leaderboard</b></h3>
            <ul className="list-unstyled">{leaderboardList}</ul>          
            <div className="mt-4">
                <h3>Nickname</h3>
            </div>
            <form onChange={(event : any) => setPlayerOneName!(event.target.value)}>
            <input id="playerName" className="inputBox" type="text" name="playerName" value={playerOneName}/>
          </form>
          <MenuButton value="Play" onSubmit={() => {
                online!.send(JSON.stringify({header: "REQUEST", name: playerOneName, gamemode: "SINGLEPLAYER"}));
                online!.addMessageHandler((data : any) => {
                    const response = JSON.parse(data.data);
                    if(response.header === "BOARD")
                        setIsGame(true);
                  })
          }}/>
        <MenuButton value="Back" onSubmit={() => setMenu(GameMode.MAIN)}/>
        </div>
    )
}
