import { Shake } from '../Game/Game';
import './CalculatorButton.css'

export enum Selected{
    None,
    PlayerOne,
    PlayerTwo
};

interface CalculatorButtonProps{
    selected : Selected;
    shake : Shake;
    value: string;
    colour: string;
    power: number;
}

export default function CalculatorButton({ selected, shake, value, colour, power } : CalculatorButtonProps){
    let classes = "calculatorButton ";
    if(selected === Selected.None)
        classes += "selectedNone "
    if(selected === Selected.PlayerOne)
        classes += "selectedPlayerOne "
    if(selected === Selected.PlayerTwo)
        classes += "selectedPlayerTwo "
    if(shake === Shake.Light)
        classes += "shakelight "
    if(shake === Shake.Heavy)
        classes += "shakeheavy "
    if(power !== 0)
        classes += "powerButton "

    if (power !== 0)
        return <div className={classes} style={{ backgroundColor: colour }}>{value}<sup>{power}</sup></div>
    else
        return <div className={classes} style={{ backgroundColor: colour }}>{value}</div>
}