import GameMode from "../../shared/gamemodes";
import "./Controls.css";

interface ControlsProps{
    gameMode : number;
    isPlayerOne : boolean;
    isOnlinePlayerTwo : boolean;
}

export default function Controls({ gameMode, isPlayerOne, isOnlinePlayerTwo } : ControlsProps){
    const id = "player" + (isPlayerOne ? "One" : "Two") + "Controls";
    
    let controls = "";

    switch(gameMode){
        case GameMode.SINGLEPLAYER:
            if(isPlayerOne)
                controls = "W A S D ⎵";
            else
                return <></>
        break;
        case GameMode.LOCAL_MULTIPLAYER:
            if(isPlayerOne)
                controls = "W A S D ⎵";
            else
                controls = "↑ ↓ → ← ↵"
        break;
        case GameMode.ONLINE_MULTIPLAYER:
            if((isPlayerOne && !isOnlinePlayerTwo) || (!isPlayerOne && isOnlinePlayerTwo))
                controls = "W A S D ⎵";            
            else
                return <></>
        break;
        default:
            return <></>
    }

    return <div id={id}>Controls: {controls}</div>
}