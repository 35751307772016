import { useState, useRef } from "react";
import Display from "../Display/Display";
import Goal from "../Goal/Goal";
import GameMode from "../../shared/gamemodes";
import PlayerInfo from "../PlayerInfo/PlayerInfo";
import CalculatorButton, { Selected } from "../CalculatorButton/CalculatorButton";
import './Game.css'
import buttonMatrix from '../../shared/buttons.json' 
import Error from "../Error/Error";
import MenuButton from "../MenuButton/MenuButton";
import { Board, Flag } from "../../shared/board";
import { Online } from "../../shared/MenuProps";

interface GameProps{
    gameMode: number;
    setIsGame: (isGame : boolean) => void;
    setMenu : (menu : number) => void;
    playerOneName : string;
    playerTwoName : string;
    maxDisplayDigits : number;
    maxGoalDigits : number;
    online : Online;
}

interface ButtonJsonEntry{
    value: string;
    power: number;
    colour: string;
    key: number;
}

enum PlayerNumber{
    None,
    One,
    Two
}

enum Operator{
    NoOperator,
    Addition,
    Subtraction,
    Division,
    Multiplication
}

export enum Shake{
    None,
    Light,
    Heavy
};

export default function Game({ gameMode, setIsGame, setMenu, playerOneName, playerTwoName, maxDisplayDigits, maxGoalDigits, online } : GameProps){
    const INITIAL_TIME = 180;
    const NUMBER_OF_BUTTON_ROWS = 4;

    let board = new Board(maxGoalDigits, maxDisplayDigits);
    const isOnlinePlayerTwo = gameMode === GameMode.ONLINE_MULTIPLAYER && !online.isPlayerOne.current;
    const effectivePlayerOneName = gameMode === GameMode.ONLINE_MULTIPLAYER ? (isOnlinePlayerTwo ? online.opponentName.current : playerOneName) : playerOneName;
    const effectivePlayerTwoName = gameMode === GameMode.ONLINE_MULTIPLAYER ?(isOnlinePlayerTwo ? playerOneName : online.opponentName.current) : playerTwoName;

    if(gameMode !== GameMode.ONLINE_MULTIPLAYER){
        board.generateAndSetGoalNumber();
    }else{
        board.goal = online.goal.current;
    }

    const [gameState, setGameState] = useState({...board})
    const [error, setError] = useState("");
    const [timer, setTimer] = useState(INITIAL_TIME);
    const [score, setScore] = useState(0);
    const [playerShakes, setPlayerShakes] = useState([Shake.None, Shake.None])
    const [playerDisplayShakes, setPlayerDisplayShakes] = useState([false, false])
    const [playerWins, setPlayerWins] = useState([false, false])

    const isMounted = useRef(false);

    const buttonListRows = [];     

    let keyDownHandler : (e : KeyboardEvent) => void;
    let keyUpHandler : (e : KeyboardEvent) => void;

    const backToMainMenu = () => {
        setIsGame(false);
        setMenu(GameMode.MAIN);
        document.removeEventListener("keydown", keyDownHandler!);
        document.removeEventListener("keyup", keyUpHandler!);

    }    

    const localMultiplayerController = () => {
        generalKeyboardHandler(keyboardEvent => {
            playerOneControlsHandlerWithoutEnterKey(keyboardEvent);
            playerTwoLocalControlsHandler(keyboardEvent);
        });                
    }

    const serverController = () => {
        generalKeyboardHandler(keyboardEvent => {
            playerOneControlsHandlerWithEnterKey(keyboardEvent);
        });

        onlineReceiverHandler();
    }

    const generalKeyboardHandler = (controlHandler : (e: KeyboardEvent) => void) => {
        let keysUp : { [key : string] : boolean; } = {
            'W': true, 'A': true, 'S': true, 'D': true, ' ': true,
            'ARROWUP': true, 'ARROWRIGHT': true, 'ARROWLEFT': true, 'ARROWDOWN': true, 'ENTER': true            
        };
        
        keyDownHandler = (e : KeyboardEvent) => {
            const keyUppercase = e.key.toUpperCase();
            if(keysUp[keyUppercase] && !(playerWins[PlayerNumber.One] || playerWins[PlayerNumber.Two])){
                keysUp[keyUppercase] = false;
                controlHandler(e);
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        keyUpHandler = (e : KeyboardEvent) => {
            keysUp[e.key.toUpperCase()] = true
        };

        document.addEventListener("keyup", keyUpHandler);
    }

    const playerOneControlsHandlerWithEnterKey = (keyboardEvent: KeyboardEvent) => {
        if(keyboardEvent.key === "Enter"){
            if(gameMode === GameMode.LOCAL_MULTIPLAYER)
                handleBoard(PlayerNumber.One, board.confirmSelection.bind(board));
            else
                online.send(JSON.stringify({header: "MOVE", direction: "SELECT", matchId : online.matchId.current, playerId : online.playerId.current}));
            return;
        }

        playerOneControlsHandlerWithoutEnterKey(keyboardEvent);        
    }

    const playerOneControlsHandlerWithoutEnterKey = (keyboardEvent: KeyboardEvent) => {
        if(gameMode === GameMode.LOCAL_MULTIPLAYER){
            switch(keyboardEvent.key.toUpperCase()){
                case 'W': handleBoard(PlayerNumber.One, board.moveUp.bind(board)); return;
                case 'A': handleBoard(PlayerNumber.One, board.moveLeft.bind(board)); return;
                case 'S': handleBoard(PlayerNumber.One, board.moveDown.bind(board)); return;
                case 'D': handleBoard(PlayerNumber.One, board.moveRight.bind(board)); return;
                case ' ': handleBoard(PlayerNumber.One, board.confirmSelection.bind(board)); return;
            }
        }else{
            switch(keyboardEvent.key.toUpperCase()){
                case 'W': online.send(JSON.stringify({header: "MOVE", direction: "UP", matchId : online.matchId.current, playerId : online.playerId.current})); return;
                case 'A': online.send(JSON.stringify({header: "MOVE", direction: "LEFT", matchId : online.matchId.current, playerId : online.playerId.current})); return;
                case 'S': online.send(JSON.stringify({header: "MOVE", direction: "DOWN", matchId : online.matchId.current, playerId : online.playerId.current})); return;
                case 'D': online.send(JSON.stringify({header: "MOVE", direction: "RIGHT", matchId : online.matchId.current, playerId : online.playerId.current})); return;
                case ' ': online.send(JSON.stringify({header: "MOVE", direction: "SELECT", matchId : online.matchId.current, playerId : online.playerId.current})); return;
            }
        }
    }

    function playerTwoLocalControlsHandler(keyboardEvent: KeyboardEvent){
        switch(keyboardEvent.key.toUpperCase()){
            case 'ARROWUP': handleBoard(PlayerNumber.Two, board.moveUp.bind(board)); return;
            case 'ARROWLEFT': handleBoard(PlayerNumber.Two, board.moveLeft.bind(board)); return;
            case 'ARROWRIGHT': handleBoard(PlayerNumber.Two, board.moveRight.bind(board)); return;
            case 'ARROWDOWN': handleBoard(PlayerNumber.Two, board.moveDown.bind(board)); return;
            case 'ENTER': handleBoard(PlayerNumber.Two, board.confirmSelection.bind(board)); return;
        }
    }

    const onlineReceiverHandler = () => {
        online.addMessageHandler((data : any) => {
            //Handle boards sent from server
            const response = JSON.parse(data.data);
            if(response.header === 'BOARD'){
                setGameState({...response.board});
                setTimer(response.timer ?? 0);
                setScore(response.score ?? 0);
                handleFlag(response.flag);
            }
        });
    }    
        
    const handleWin = () => {       
        const togglePlayerWins = () => {
            setGameState(gameState => {
                setPlayerWins(oldPlayerWins => {
                    const newPlayerWins = [...oldPlayerWins];                    
    
                    let winnerPlayerNumber = PlayerNumber.None;
                    if(parseInt(gameState.playerOne.display) === gameState.goal){
                        winnerPlayerNumber = PlayerNumber.One;
                    }else if(parseInt(gameState.playerTwo.display) === gameState.goal){
                        winnerPlayerNumber = PlayerNumber.Two;
                    }else{
                        return [false, false];
                    }
    
                    newPlayerWins[winnerPlayerNumber] = !oldPlayerWins[winnerPlayerNumber];
    
                    return newPlayerWins;
                })
            
                return gameState;
            })
        }

        togglePlayerWins();

        return new Promise((resolve) => {
            setTimeout(() => {
                togglePlayerWins();
                if(gameMode === GameMode.LOCAL_MULTIPLAYER){
                    board.startNextMatch();
                    setGameState({...board})
                }
                resolve('Finished animation');
            }, 3000)
        })
    }

    const handleFlag = async (flag : Flag) => {       
        switch(flag){
            case Flag.DAMAGED_HIGH_HEALTH:
                shakePlayerMomentarily(PlayerNumber.One, Shake.Light);
                shakePlayerMomentarily(PlayerNumber.Two, Shake.Light);
            break;            
            case Flag.DAMAGED_LOW_HEALTH:
                shakePlayerMomentarily(PlayerNumber.One, Shake.Heavy);
                shakePlayerMomentarily(PlayerNumber.Two, Shake.Heavy);
            break;
            case Flag.PLAYER_ONE_DISPLAY_INVALID:
                shakePlayerDisplayMomentarily(PlayerNumber.One);
            break;
            case Flag.PLAYER_TWO_DISPLAY_INVALID:
                shakePlayerDisplayMomentarily(PlayerNumber.Two);
            break;
            case Flag.PLAYER_ONE_MOVE_INVALID:
                shakePlayerMomentarily(PlayerNumber.One, Shake.Light);
            break;
            case Flag.PLAYER_TWO_MOVE_INVALID:
                shakePlayerMomentarily(PlayerNumber.Two, Shake.Light);
            break;
            case Flag.FINISHED_MATCH:
                handleWin();
            break;
            case Flag.FINISHED_GAME:
                await handleWin();
                backToMainMenu();
            break;
            case Flag.TIMEOUT:
                console.log("Timeout")
                setError("Other player disconnected, leaving match...");
                await handleWin();
                backToMainMenu();
            break;
            case Flag.NO_FLAG:
            default:
                return;            
        }
    }
    
    const handleBoard = (playerNumber : number, boardMethod : (isPlayerOne : boolean) => Flag) => {
        const flag = boardMethod(playerNumber === PlayerNumber.One);
        setGameState({...board});
        handleFlag(flag);
    }

    const shakePlayerMomentarily = (playerNumber : number, intensity : number) => {        
        setTimeout(() => {
            setShakePlayer(playerNumber, Shake.None)
        }, 300)

        setShakePlayer(playerNumber, intensity);
    }    

    const setShakePlayer = (playerNumber : number, intensity : number) => {
        setPlayerShakes(oldPlayerShakes => {
            const newPlayerShakes = [...oldPlayerShakes]
            newPlayerShakes[playerNumber] = intensity;
            return newPlayerShakes;
        })
    }

    const shakePlayerDisplayMomentarily = (playerNumber : number) => {        
        setTimeout(() => {
            setShakePlayerDisplay(playerNumber, false)
        }, 300)

        setShakePlayerDisplay(playerNumber, true);
    }

    const setShakePlayerDisplay = (playerNumber : number, isShake : boolean) => {
        setPlayerDisplayShakes(oldPlayerDisplayShakes => {
            const newPlayerDisplayShakes = [...oldPlayerDisplayShakes]
            newPlayerDisplayShakes[playerNumber] = isShake;
            return newPlayerDisplayShakes;
        })
    }

    //Render and highlight buttons
    for(let rowNo = 0; rowNo < NUMBER_OF_BUTTON_ROWS; rowNo++){
        buttonListRows.push(buttonMatrix[rowNo].map((button : ButtonJsonEntry) => 
            {
                let squareSelectedByWhichPlayer;
                let squareShake;

                switch(button.key){
                    case gameState.playerOne.position:
                        squareSelectedByWhichPlayer = Selected.PlayerOne;
                        squareShake = playerShakes[PlayerNumber.One];
                        break;
                    case gameState.playerTwo.position:
                        squareSelectedByWhichPlayer = Selected.PlayerTwo;
                        squareShake = playerShakes[PlayerNumber.Two];
                        break;
                    default:
                        squareSelectedByWhichPlayer = Selected.None;
                        squareShake = Shake.None;
                }
                
                const isOperatorSelectedByPlayer = (playerNumber : PlayerNumber) => {
                    const player = playerNumber === PlayerNumber.One ? gameState.playerOne : gameState.playerTwo;
                    return (button.value === "*" && player.operator === Operator.Multiplication)
                    || (button.value === "/" && player.operator === Operator.Division)
                    || (button.value === "-" && player.operator === Operator.Subtraction)
                    || (button.value === "+" && player.operator === Operator.Addition)
                }

                if(isOperatorSelectedByPlayer(PlayerNumber.One) && isOperatorSelectedByPlayer(PlayerNumber.Two))
                    button.colour = 'slateblue'                
                else if(isOperatorSelectedByPlayer(PlayerNumber.One))
                    button.colour = 'lightcoral'
                else if(isOperatorSelectedByPlayer(PlayerNumber.Two))
                    button.colour = 'steelblue'
                else if(button.value.match(/^[*\-+\/]$/gm))
                    button.colour = 'plum'

                return (
                    <li key={button.key} className="list-group-item">
                        <CalculatorButton selected={squareSelectedByWhichPlayer} shake={squareShake} value={button.value} colour={button.colour} power={button.power}/>
                    </li>
                )
            }
        ))
    }

    //Select controller listener
    if(!isMounted.current){
        switch(gameMode){
            case GameMode.SINGLEPLAYER:
                serverController();
            break;
            case GameMode.LOCAL_MULTIPLAYER:
                localMultiplayerController();
                break;         
            case GameMode.ONLINE_MULTIPLAYER:
                serverController();
            break;
            case GameMode.MAIN:
                backToMainMenu();
            break;   
            break;
        }
        
        isMounted.current = true;
    }

    return (
        <div>
            <PlayerInfo points={gameState.playerOne.points} timer={timer} name={effectivePlayerOneName} isPlayerOne={true} gameMode={gameMode} isOnlinePlayerTwo={isOnlinePlayerTwo}/>
            <PlayerInfo points={gameState.playerTwo.points} name={effectivePlayerTwoName} isPlayerOne={false} gameMode={gameMode} isOnlinePlayerTwo={isOnlinePlayerTwo}/>
            <Goal isWin={playerWins[PlayerNumber.One] || playerWins[PlayerNumber.Two]} goalValue={gameState.goal}/> 
            <div className="d-flex">
                <div className={"flex-md-fill mt-5 justify-content-start playerDisplay"}>
                    <Display gameMode={gameMode} isPlayerOne={true} value={gameState.playerOne.display} isWin={playerWins[PlayerNumber.One]} isShake={playerDisplayShakes[PlayerNumber.One]} />
                </div>
                <div className={"flex-md-fill mt-5 justify-content-end playerDisplay"}>
                    <Display gameMode={gameMode} isPlayerOne={false} value={gameMode === GameMode.SINGLEPLAYER ? score.toString() : gameState.playerTwo.display} isWin={playerWins[PlayerNumber.Two]} isShake={playerDisplayShakes[PlayerNumber.Two]}/>
                </div>
            </div>
            <div className="buttonContainer">
                <ul className="list-group list-group-horizontal">{buttonListRows[0]}</ul>
                <ul className="list-group list-group-horizontal">{buttonListRows[1]}</ul>        
                <ul className="list-group list-group-horizontal">{buttonListRows[2]}</ul>
                <ul className="list-group list-group-horizontal">{buttonListRows[3]}</ul>
            </div>
            <Error message={error}/>
        </div>
    );
}